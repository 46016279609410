<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useQuery } from 'vue-query';

import serviceHistoryApi from '@/api/user-services-history';

import UserServicesHistoryIndexViewItem from './user-services-history-index-view-item.vue';
import UserServicesHistoryLoading from './user-services-history-loading.vue';

const { t } = useI18n();

const { isFetching, isError, refetch, data: servicesHistory } =
  useQuery({
    queryKey: ['service-history'],
    queryFn: () => serviceHistoryApi.index()
      .then((response) => response.data),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    initialData: [],
  });

const breadcrumbItems = [
  { label: t('services.title'), href: '/' },
  { label: t('serviceHistory.title'), href: '/user/service-history' },
];

const results = computed(() => servicesHistory &&
  servicesHistory.value &&
  servicesHistory.value.length > 0,
);

const noResults = computed(() => !isFetching.value && !results.value);
</script>

<template>
  <div class="mx-auto w-full px-6 pt-10 text-gray-800 sm:px-16 md:max-w-screen-2xl md:pt-14">
    <base-breadcrumb
      :items="breadcrumbItems"
      class="mb-8 md:mb-12"
    />
    <h1
      v-if="results"
      class="mb-2 text-2xl font-semibold md:mb-4 md:text-4xl"
    >
      {{ $t('serviceHistory.title') }}
    </h1>
    <p
      class="mb-8 text-sm font-medium text-gray-500 md:mb-12 md:text-lg"
    >
      <template v-if="isFetching">
        {{ $t('serviceHistory.loadingServicesSubtitle') }}
      </template>
      <template v-else-if="results && servicesHistory">
        {{ $t('serviceHistory.requestedServices', servicesHistory.length) }}
      </template>
    </p>
    <div class="mb-8 flex flex-col gap-6 md:gap-8">
      <user-services-history-loading
        v-if="isFetching || isError"
        :is-fetching="isFetching"
        :is-error="isError"
        :error-message="$t('serviceHistory.errorLoadingServices')"
        :loading-message="$t('serviceHistory.loadingServices')"
        @refetch="refetch"
      />
      <base-mok-empty-state
        v-else-if="noResults"
        :title="$t('serviceHistory.noServices.title')"
        :button-label="$t('serviceHistory.noServices.requestServices')"
        button-href="/#main-content"
      >
        {{ $t('serviceHistory.noServices.description') }}
      </base-mok-empty-state>
      <template v-else>
        <user-services-history-index-view-item
          v-for="item in servicesHistory"
          :key="item.id"
          :item="item"
        />
      </template>
    </div>
  </div>
</template>
