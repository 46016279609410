import { AxiosPromise } from 'axios';

import { UserServiceHistoryItem } from '@/types/user-services-history-item';

import api from './index';

export default {
  async index() {
    const path = '/api/internal/user/services_history';

    return api({
      method: 'get',
      url: path,
    }) as AxiosPromise<UserServiceHistoryItem[]>;
  },
  async show(otId: number) {
    const path = `/api/internal/user/services_history/${otId}`;

    return api({
      method: 'get',
      url: path,
    }) as AxiosPromise<UserServiceHistoryItem>;
  },
};
