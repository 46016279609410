<script setup lang="ts">
import { computed } from 'vue';

import StepDone from '../../assets/images/step-done.svg';
import StepInactive from '../../assets/images/step-inactive.svg';
import StepProgress from '../../assets/images/step-progress.svg';

const props = withDefaults(defineProps<{
  steps: unknown[];
  currentStep: number;
  direction?: 'horizontal' | 'vertical';
}>(), {
  direction: 'horizontal',
});

function getStepIcon(currentStep: number, index: number) {
  if (index === currentStep) {
    return StepProgress;
  } else if (index < currentStep) {
    return StepDone;
  }

  return StepInactive;
}

const isVertical = computed(() => props.direction === 'vertical');
</script>

<template>
  <div
    class="flex items-stretch"
    :class="isVertical ? 'flex-col' : 'w-full'"
  >
    <div
      v-for="(_, index) in props.steps"
      :key="index"
      class="relative flex items-center justify-center"
      :class="{
        'w-full': !isVertical && index < props.steps.length - 1,
        'h-full': isVertical,
      }"
    >
      <component
        :is="getStepIcon(props.currentStep, index)"
        class="relative z-10 min-w-6 max-w-6 fill-primary"
        :data-testId="`step-icon-${index}`"
      />
      <div
        v-if="(!isVertical && index < props.steps.length - 1) || isVertical"
        :class="{
          'h-0.5 w-full': !isVertical,
          'absolute z-0 w-0.5': isVertical,
          'bottom-0 top-[calc(50%+.75rem)]' : isVertical && index !== props.steps.length - 1,
          'bg-primary': index < props.currentStep || (isVertical && index === props.currentStep),
          'bg-gray-300': index > props.currentStep,
          'bg-gradient-to-r from-primary from-30% to-gray-300 to-30%': !isVertical && index === props.currentStep,
        }"
      />
      <div
        v-if="isVertical && index !== 0"
        class="absolute bottom-[calc(50%+0.75rem)] top-0 z-0 w-0.5"
        :class="{
          'bg-primary': index <= props.currentStep,
          'bg-gray-300': index > props.currentStep
        }"
      />
    </div>
  </div>
</template>
