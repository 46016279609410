<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';

const props = defineProps<{
  contactCenterPhone: string;
}>();

const { greaterOrEqual } = useBreakpoints(breakpointsTailwind);
const mdOrLarger = greaterOrEqual('md');
</script>

<template>
  <div class="mb-8 rounded-lg bg-primary-500 p-4 text-center text-white md:mb-12 md:p-6">
    <h2 class="mb-2 text-xl font-semibold md:text-3xl">
      {{ $t('serviceHistory.show.helpBox.title') }}
    </h2>
    <i18n-t
      tag="p"
      keypath="serviceHistory.show.helpBox.text"
      class="mb-4 text-sm font-medium md:text-lg"
    >
      <template #newline>
        <br>
      </template>
      <template #contactPhone>
        {{ props.contactCenterPhone }}
      </template>
    </i18n-t>
    <base-mok-button
      variant="secondary"
      :size="mdOrLarger ? 'medium' : 'small'"
      :label="$t('serviceHistory.show.helpBox.ctaButton')"
    />
  </div>
</template>
