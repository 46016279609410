<script setup lang="ts">
import { ref, provide } from 'vue';
import { useQuery } from 'vue-query';

import pointsApi, { type ResponsePoints } from '@/api/points';
import useLoyaltyInfoModals from '@/composables/useLoyaltyInfoModals';
import useNotifications, { type NotificationMessage } from '@/composables/useNotifications';
import useSiteVariables from '@/composables/useSiteVariables';
import type { Country } from '@/types/country';
import type { User } from '@/types/user';
import type { WebApp } from '@/types/web-app';

import LoginRedirect from './login-redirect.vue';
import LoyaltyInfoModals from './loyalty-info-modals.vue';

interface Props {
  webApp: WebApp;
  country: Country;
  loginUrl: string;
  notice?: NotificationMessage;
  currentUser?: User;
  points?: number;
  units?: string;
  hideNavbar?: boolean;
  hideFooter?: boolean;
}

const props = withDefaults(
  defineProps<Props>(),
  {
    authUrl: undefined,
    loginUrl: undefined,
    notice: undefined,
    currentUser: undefined,
    points: undefined,
    units: undefined,
    hideNavbar: false,
    hideFooter: false,
  },
);

const userPoints = ref(props.points);
const showLoginRedirect = ref(false);

const { hasLoyalty } = useSiteVariables();
if (props.notice) {
  useNotifications(props.notice, true);
}
const loyaltyQueryKey = 'loyalty-get-points';
useQuery(loyaltyQueryKey, pointsApi.points, {
  onSuccess: (response: ResponsePoints) => {
    userPoints.value = response.data.points;
  },
  enabled: !!props.currentUser && hasLoyalty,
});

function toggleServiceLoginRedirect() {
  showLoginRedirect.value = !showLoginRedirect.value;
}

function toggleCouponLoginRedirect() {
  showLoginRedirect.value = !showLoginRedirect.value;
}

function closeLoginRedirectModal() {
  showLoginRedirect.value = false;
}

const { loyaltyInfoModalsState, openLoyaltyModal, closeLoyaltyInfoModals } = useLoyaltyInfoModals();

provide('webApp', props.webApp);
provide('country', props.country);
provide('points', userPoints);
provide('units', props.units);
provide('currentUser', props.currentUser);
provide('openLoyaltyModal', openLoyaltyModal);
provide('toggleServiceLoginRedirect', toggleServiceLoginRedirect);
provide('toggleCouponLoginRedirect', toggleCouponLoginRedirect);

</script>
<template>
  <div class="flex min-h-screen flex-col justify-between">
    <div class="flex grow flex-col">
      <slot
        v-if="!hideNavbar"
        name="navbar"
        data-testid="navbar-slot"
      />
      <notifications
        class="mt-20 md:mr-6"
        position="top right"
      >
        <template #body="noticeProp">
          <base-badge
            theme="medium"
            :color="noticeProp.item.type"
            :label="$t('noticeProp.item.text')"
          />
        </template>
      </notifications>
      <slot />
    </div>
    <slot
      v-if="!hideFooter"
      name="footer"
      data-testid="footer-slot"
    />
  </div>
  <loyalty-info-modals
    v-if="hasLoyalty"
    :loyalty-info-modals-state="loyaltyInfoModalsState"
    @close="closeLoyaltyInfoModals"
  />
  <login-redirect
    v-if="loginUrl"
    :open="showLoginRedirect"
    :login-url="loginUrl"
    @close="closeLoginRedirectModal"
  />
</template>
