<script setup lang="ts">
import { PhCircleNotch, PhXCircle } from '@phosphor-icons/vue';

const props = defineProps<{
  isFetching: boolean;
  isError: boolean;
  errorMessage: string;
  loadingMessage: string;
}>();

const emit = defineEmits<{(event: 'refetch'): void;
}>();
</script>

<template>
  <div class="mb-8 flex flex-col items-center justify-center gap-4 bg-gray-100 px-4 py-24 text-center text-xl font-semibold md:gap-9 md:rounded-lg md:px-8 md:py-16 md:text-3xl">
    <template v-if="isFetching">
      <ph-circle-notch class="size-12 animate-spin text-primary md:size-16" />
      {{ props.loadingMessage }}
    </template>
    <template v-else-if="isError">
      <ph-x-circle class="size-12 text-error-500 md:size-16" />
      <p>{{ props.errorMessage }}</p>
      <base-mok-button
        :label="$t('serviceHistory.reloadServices')"
        variant="secondary"
        size="medium"
        @click="emit('refetch')"
      />
    </template>
  </div>
</template>
