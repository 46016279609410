import type { AxiosPromise } from 'axios';

import api from './index';

const BASE_PATH = '/api/internal/services/';

interface AddressTiming {
  route: string;
  locality: string;
  administrative_area_level_1: string;
  country: string;
  latitude: number;
  longitude: number;
}

export interface Values {
  [key: string]: string | AddressTiming | undefined;
  'address-timing'?: AddressTiming;
}

interface ResponseAssistance {
  message: string;
  urlTracking: string;
}

interface PreRedirectCallbackResponse {
  redirectUrl: string;
}

export default {
  assistances(serviceId: number, contractId: string, data: Values) {
    const path = `${BASE_PATH}${serviceId}/assistances`;

    return api({
      method: 'post',
      url: path,
      data: { serviceData: data, contractId },
    }) as AxiosPromise<ResponseAssistance>;
  },
  preRedirectCallback(serviceId: number) {
    const path = `${BASE_PATH}${serviceId}/pre_redirect_callback`;

    return api({
      method: 'get',
      url: path,
    }) as AxiosPromise<PreRedirectCallbackResponse>;
  },
};
