import { DateTimeFormatOptions } from '@intlify/core-base';

import enUSLocale from './enUS.json';
import esCLLocale from './esCL.json';
import esEsLocale from './esES.json';
import frFRLocale from './frFR.json';
import ptBRLocale from './ptBR.json';
import ptPTLocale from './ptPT.json';

const messages = {
  'en-US': enUSLocale,
  'es-CL': esCLLocale,
  'es-ES': esEsLocale,
  'fr-FR': frFRLocale,
  'pt-BR': ptBRLocale,
  'pt-PT': ptPTLocale,
};

const defaultDatetimeFormat: Record<string, DateTimeFormatOptions> = {
  short: {
    year: 'numeric', month: 'short', day: 'numeric',
  },
  long: {
    year: 'numeric', month: 'long', day: 'numeric',
    hour: 'numeric', minute: 'numeric',
  },
} as const;

const datetimeFormats = {
  'en-US': defaultDatetimeFormat,
  'es-CL': defaultDatetimeFormat,
  'es-ES': defaultDatetimeFormat,
  'fr-FR': defaultDatetimeFormat,
  'pt-BR': defaultDatetimeFormat,
  'pt-PT': defaultDatetimeFormat,
};

export default { messages, datetimeFormats };
