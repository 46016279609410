<script setup lang="ts">
import { PhInfo, PhHandTap } from '@phosphor-icons/vue';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { Component } from 'vue';

const { greaterOrEqual } = useBreakpoints(breakpointsTailwind);
const mdOrLarger = greaterOrEqual('md');

type Props = {
  title: string;
  icon?: Component;
  buttonLabel?: string;
  buttonIcon?: Component;
  buttonHref?: string;
};

withDefaults(defineProps<Props>(), {
  icon: PhInfo,
  buttonLabel: undefined,
  buttonIcon: PhHandTap,
  buttonHref: undefined,
});

const emit = defineEmits<{'click': [event: MouseEvent]}>();
</script>

<template>
  <div class="mb-8 flex flex-col items-center justify-center px-4 py-24 text-center md:gap-9 md:px-8 md:py-16">
    <div class="mx-auto flex max-w-[406px] flex-col items-center justify-center text-center">
      <base-mok-icon
        :icon="icon"
        :size="mdOrLarger ? '2xl' : 'xl'"
        class="mb-4 md:mb-6"
      />
      <p class="mb-4 text-xl font-medium leading-8 md:text-3xl md:leading-10">
        {{ title }}
      </p>
      <div class="mb-6 text-sm text-gray-600 md:mb-8 md:text-base">
        <slot />
      </div>
      <base-mok-button
        v-if="buttonLabel"
        :href="buttonHref"
        :icon="buttonIcon"
        :label="buttonLabel"
        full-width
        :size="mdOrLarger ? 'large' : 'medium'"
        @click="emit('click', $event)"
      />
    </div>
  </div>
</template>
