<script setup lang="ts">
const props = defineProps<{
  infoList: {
    term: string;
    detail?: string | number;
  }[];
}>();
</script>

<template>
  <div class="mb-8 flex flex-col gap-4 rounded-lg bg-gray-100 p-4 md:mb-12 md:p-9">
    <dl class="grid gap-x-8 text-sm md:grid-cols-2">
      <div
        v-for="(info) in props.infoList"
        :key="info.term"
        class="mb-3 last:mb-0"
      >
        <dt class="px-2 text-gray-500">
          {{ info.term }}
        </dt>
        <dd
          class="truncate border-b border-gray-300 px-2 pb-3 font-semibold text-gray-800"
        >
          {{ info.detail }}
        </dd>
      </div>
    </dl>
  </div>
</template>
