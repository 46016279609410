<script setup lang="ts">
const props = defineProps<{
  items: { label: string; href?: string }[];
}>();
</script>

<template>
  <ul class="text-sm font-medium md:text-lg">
    <li
      v-for="(item, index) in props.items"
      :key="item.label"
      class="mr-1 inline"
    >
      <component
        :is="item.href ? 'a' : 'span'"
        :href="item.href"
        class="mr-1 transition-colors duration-150 ease-linear hover:text-primary-700"
        :class="index === props.items.length - 1 ? 'text-primary-700' : 'text-gray-800'"
      >
        {{ item.label }}
      </component>
      <span v-if="index < props.items.length - 1">&gt;</span>
    </li>
  </ul>
</template>
