<script setup lang="ts">
import { PhPhone } from '@phosphor-icons/vue';
import { computed } from 'vue';

import type { FirstFooter } from '@/types/first-footer';

import LegalDocumentsLink from '../../legal-documents-links.vue';

interface Props {
  firstFooter: FirstFooter;
}

const colorStyles = {
  primary: {
    bg: 'bg-primary',
    text: 'text-primary',
  },
  secondary: {
    bg: 'bg-secondary',
    text: 'text-secondary',
  },
  black: {
    bg: 'bg-black',
    text: 'text-black',
  },
  white: {
    bg: 'bg-white',
    text: 'text-white',
  },
};
const props = defineProps<Props>();
const extendedBgColorVariant = computed(() => props.firstFooter.extendedBgColor || 'black');
const textColorVariant = computed(() => props.firstFooter.textColor || 'white');
const extendedBgColorClass = computed(() => colorStyles[extendedBgColorVariant.value].bg);
const textColorClass = computed(() => colorStyles[textColorVariant.value].text);
const MAX_LOGO_COUNT = 4;
const logos = computed(() => [
  { url: props.firstFooter.firstLogoUrl, webpUrl: props.firstFooter.firstLogoWebpUrl,
    blurhash: props.firstFooter.firstLogoBlurhash },
  { url: props.firstFooter.secondLogoUrl, webpUrl: props.firstFooter.secondLogoWebpUrl,
    blurhash: props.firstFooter.secondLogoBlurhash },
  { url: props.firstFooter.thirdLogoUrl, webpUrl: props.firstFooter.thirdLogoWebpUrl,
    blurhash: props.firstFooter.thirdLogoBlurhash },
  { url: props.firstFooter.fourthLogoUrl, webpUrl: props.firstFooter.fourthLogoWebpUrl,
    blurhash: props.firstFooter.fourthLogoBlurhash },
  { url: props.firstFooter.fifthLogoUrl, webpUrl: props.firstFooter.fifthLogoWebpUrl,
    blurhash: props.firstFooter.fifthLogoBlurhash },
]);

const hasValidLogos = computed(() => logos.value.some(logo => logo.url));
function shouldShowSeparator(logo: {
  url: string | undefined;
  webpUrl: string | undefined;
  blurhash: string | undefined;
}) {
  return logo.url && logos.value.indexOf(logo) < MAX_LOGO_COUNT && logos.value.filter(l => l.url).length > 1;
}
</script>

<template>
  <div
    class="w-full"
    :class="[extendedBgColorClass, textColorClass]"
  >
    <div class="mx-auto flex w-full flex-col justify-between gap-y-4 px-6 py-8 sm:px-16 md:max-w-screen-2xl md:flex-row md:py-16">
      <div class="flex flex-wrap justify-center gap-10 md:items-start md:justify-start">
        <template v-if="!hasValidLogos">
          <img
            class="h-auto w-full max-w-20 overflow-hidden rounded-lg object-contain"
            :alt="$t('common.logo')"
            :src="props.firstFooter.darkBgLogoUrl"
          >
        </template>
        <template
          v-for="logo in logos"
          v-else
          :key="logo.url"
        >
          <base-image
            v-if="logo.url"
            class="h-auto w-full max-w-20 overflow-hidden rounded-lg object-contain"
            :src="logo.url"
            :webp-src="logo.webpUrl"
            :blurhash="logo.blurhash"
          />
          <div
            v-if="shouldShowSeparator(logo)"
            class="hidden h-24 w-0.5 border-l border-gray-200 md:block"
          />
        </template>
      </div>
      <div
        class="mx-auto flex flex-col py-10 md:mr-0 md:w-max"
      >
        <div
          v-if="firstFooter.contactPhone"
          class="flex text-base"
        >
          <div class="flex flex-col space-y-4">
            <p class="text-justify">
              {{ $t('sections.footers.firstFooter.contactPhoneCta') }}
            </p>
            <div class="flex">
              <PhPhone
                :size="32"
                weight="fill"
                class="mr-6 flex items-center justify-center fill-current"
                :class="[textColorClass]"
              />
              <div class="font-bold">
                <p v-if="firstFooter.contactPhone">
                  {{ firstFooter.contactPhone }}
                </p>
                <p v-if="firstFooter.secondaryContactPhone">
                  {{ firstFooter.secondaryContactPhone }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <base-image
          v-if="firstFooter.regulatoryComplianceImageUrl"
          class="h-auto w-full max-w-sm overflow-hidden rounded-2xl object-cover py-4 lg:rounded-3xl"
          :src="firstFooter.regulatoryComplianceImageUrl"
          :webp-src="firstFooter.regulatoryComplianceWebpImageUrl"
          :blurhash="firstFooter.regulatoryComplianceImageBlurhash"
        />
      </div>
      <legal-documents-link class="mt-6 flex flex-col gap-1 text-xs text-white sm:mt-1" />
    </div>
  </div>
</template>
