type SiteVariables = {
  locale: string;
  hasLoyalty: boolean;
  isLoginRequired: boolean;
  isTermsAndConditionsActive: boolean;
  isPrivacyPolicyActive: boolean;
  isRedeMaisSaude: boolean;
  featureFlags: {
    serviceHistory: boolean;
  },
}

export interface WebappWindow extends Window {
  siteVariables: SiteVariables;
}

export default function useSiteVariables() {
  return (window as WebappWindow & typeof globalThis).siteVariables;
}
