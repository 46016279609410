<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useQuery } from 'vue-query';

import serviceHistoryApi from '@/api/user-services-history';
import UserServicesHistoryLoading from '@/components/user-services-history-loading.vue';
// eslint-disable-next-line max-len, vue/max-len
import UserServicesHistoryShowViewGroupedDetails from '@/components/user-services-history-show-view-grouped-details.vue';
import UserServicesHistoryShowViewHelpBox from '@/components/user-services-history-show-view-help-box.vue';
import UserServicesHistoryShowViewSteps from '@/components/user-services-history-show-view-steps.vue';

const { t, d } = useI18n();

const props = defineProps<{
  otId: number;
}>();

const { isFetching, isError, refetch, data: serviceHistoryItem } =
  useQuery({
    queryKey: ['service-history', props.otId],
    queryFn: () =>
      serviceHistoryApi.show(props.otId)
        .then((response) => response.data),
    refetchOnWindowFocus: false,
  });

const breadcrumbItems = [
  { label: t('services.title'), href: '/' },
  { label: t('serviceHistory.title'), href: '/user/services_history' },
  { label: t('serviceHistory.show.title') },
];

const scheduledAt = computed(
  () => {
    const item = serviceHistoryItem.value;
    if (!item || !item.scheduledDate || !item.scheduledTime) return undefined;

    return new Date(`${item.scheduledDate}T${item.scheduledTime}`);
  },
);

const serviceInfo = computed(() => [
  {
    term: t('serviceHistory.show.serviceInfo.date'),
    detail: scheduledAt.value ? d(scheduledAt.value, 'long') : undefined,
  },
  {
    term: t('serviceHistory.show.serviceInfo.caseId'),
    detail: serviceHistoryItem.value?.caseId,
  },
  {
    term: t('serviceHistory.show.serviceInfo.originAddress'),
    detail: serviceHistoryItem.value?.originAddress,
  },
  {
    term: t('serviceHistory.show.serviceInfo.destinationAddress'),
    detail: serviceHistoryItem.value?.destinationAddress,
  },
  {
    term: t('serviceHistory.show.serviceInfo.userPhoneNumber'),
    detail: serviceHistoryItem.value?.phone,
  },
].filter((info) => info.detail?.toString().trim()));

const providerInfo = computed(() => [
  {
    term: t('serviceHistory.show.providerInfo.name'),
    detail: serviceHistoryItem.value?.providerName,
  },
  {
    term: t('serviceHistory.show.providerInfo.resourceName'),
    detail: serviceHistoryItem.value?.technicianName,
  },
  {
    term: t('serviceHistory.show.providerInfo.phoneNumber'),
    detail: serviceHistoryItem.value?.providerPhone,
  },
].filter((info) => info.detail));

const showProviderInfo = computed(() => {
  const item = serviceHistoryItem.value;
  const statuses = ['cancelled', 'pending'];

  return item && !statuses.includes(item.currentStatus) && providerInfo.value.length > 0;
});
</script>

<template>
  <div class="mx-auto w-full px-6 pt-10 sm:px-16 md:max-w-screen-2xl md:pt-14">
    <base-breadcrumb
      :items="breadcrumbItems"
      class="mb-8 md:mb-12"
    />
    <user-services-history-loading
      v-if="isFetching || isError"
      :is-fetching="isFetching"
      :is-error="isError"
      :error-message="$t('serviceHistory.errorLoadingServices')"
      :loading-message="$t('serviceHistory.show.loadingService')"
      @refetch="refetch"
    />
    <div
      v-else-if="serviceHistoryItem"
      class="mb-8 flex flex-col"
    >
      <div class="mb-8 flex flex-col gap-4 rounded-lg bg-gray-100 px-4 py-6 md:mb-12 md:flex-row md:items-start md:gap-16 md:p-9">
        <div class="flex items-center gap-4 md:flex-col-reverse md:items-start">
          <img
            :src="serviceHistoryItem.service.imageUrl"
            class="aspect-square h-16 shrink-0 overflow-hidden rounded-lg object-cover md:aspect-auto md:h-full md:max-h-[318px]"
            :alt="serviceHistoryItem.service.name"
          >
          <div>
            <h1 class="text-xl font-semibold text-gray-800">
              {{ serviceHistoryItem?.service.name }}
            </h1>
            <p
              v-if="scheduledAt"
              class="text-sm text-gray-500"
            >
              {{ $d(scheduledAt, 'long') }}
            </p>
          </div>
        </div>
        <user-services-history-show-view-steps
          :current-status="serviceHistoryItem.currentStatus"
          :status-history="serviceHistoryItem.statusHistory"
        />
      </div>
      <user-services-history-show-view-help-box
        v-if="serviceHistoryItem.contactCenterPhone"
        :contact-center-phone="serviceHistoryItem.contactCenterPhone"
      />
      <h2 class="mb-4 text-xl font-medium text-gray-800 md:mb-6 md:text-3xl">
        {{ $t('serviceHistory.show.serviceInfo.title') }}
      </h2>
      <user-services-history-show-view-grouped-details
        :info-list="serviceInfo"
      />
      <template v-if="showProviderInfo">
        <h2 class="mb-4 text-xl font-medium text-gray-800 md:mb-6 md:text-3xl">
          {{ $t('serviceHistory.show.providerInfo.title') }}
        </h2>
        <user-services-history-show-view-grouped-details
          :info-list="providerInfo"
        />
      </template>
    </div>
  </div>
</template>
