<script setup lang="ts">
import groupBy from 'lodash/groupBy';
import type { FormContext } from 'vee-validate';
import { ref, computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMutation, useQueryClient } from 'vue-query';
import * as yup from 'yup';

import serviceApi, { type Values } from '@/api/service';
import serviceRedemptionApi from '@/api/service-redemption';
import useSiteVariables from '@/composables/useSiteVariables';
import useStepper from '@/composables/useStepper';
import type { Input } from '@/types/input';
import type { NestedInput } from '@/types/nested-input';
import type { Service } from '@/types/service';
import type { WebApp } from '@/types/web-app';

import BaseMokStepper from './base-mok-stepper.vue';
import LoyaltyRedemptionErrorModal from './loyalty-redemption-error-modal.vue';
import ServiceFormCard from './service-form-card.vue';
import ServiceFormErrorModal from './service-form-error-modal.vue';
import ServiceFormSuccessPage from './service-form-success-page.vue';

const props = defineProps<{
  webApp: WebApp;
  service: Service;
  backUrl: string;
  countryFlagIcon?: string;
  phonePrefix: string;
  mobilePhonePattern: string
}>();
const { hasLoyalty } = useSiteVariables();
const queryClient = useQueryClient();
const loyaltyQueryKey = 'loyalty-get-points';

const { t } = useI18n({});

const groupedInputs = groupBy(props.service.inputs, 'kind');

const showSuccessPage = ref(false);
const showRedemptionError = ref(false);
const form = ref<FormContext | null>(null);
const formValues = ref<Values>({});
const steps = Object.keys(groupedInputs);
const translatedFormValues = ref<Values>({});
const formError = ref<boolean>(false);

const {
  currentStepIndex,
  nextStep,
  isLastStep,
  previousStep,
} = useStepper(steps);

function createInputSchema(input: Input) {
  const label = input.customName || t(`serviceFormInfo.${input.kind}.inputs.${input.name}`) || input.name;
  const baseSchema = yup.string().required().label(label);
  const schemaByType: Record<string, yup.AnySchema> = {
    'google_autocomplete': yup.object().required().label(label),
    'mobile_phone': baseSchema.matches(
      new RegExp(`${props.mobilePhonePattern}$`),
      t('serviceFormInfo.personal.inputs.mobilePhoneNumberValidation'),
    ),
  };
  const schema = schemaByType[input.inputType] || baseSchema;

  return {
    [`${input.name}-${input.kind}`]: schema,
  };
}

function createNestedInputSchema(input: NestedInput) {
  const labelFirst = t(`serviceFormInfo.${input.kind}.inputs.${input.firstName}`) || input.firstName;
  const labelSecond = t(`serviceFormInfo.${input.kind}.inputs.${input.secondName}`) || input.secondName;

  return {
    [`${input.firstName}-${input.kind}`]: yup.string().required().label(labelFirst),
    [`${input.secondName}-${input.kind}`]: yup.string().required().label(labelSecond),
  };
}

const validationSchemas = computed(() => Object.keys(groupedInputs).map((group) => {
  const schema = {};

  groupedInputs[group].forEach((input) => {
    if ('apiName' in input && !input.optional) {
      Object.assign(schema, createInputSchema(input));
    } else if ('firstApiName' in input) {
      Object.assign(schema, createNestedInputSchema(input));
    }
  });

  return yup.object().shape(schema);
}));

const {
  mutate: updateServiceRedemptionMutate,
  isLoading: updateServiceRedemptionIsLoading,
} = useMutation(
  () => serviceRedemptionApi.update(props.service.lastRedemption?.id as number, new Date().toISOString()),
  {
    onSuccess: async () => {
      await queryClient.invalidateQueries(loyaltyQueryKey);
    },
    onError: () => {
      showRedemptionError.value = true;
    },
  },
);

const hasLoyaltyCostAndRedemption = computed(() => (
  hasLoyalty && props.service.pointCost > 0 && props.service.lastRedemption
));

const {
  mutate: assistancesMutate,
  isLoading: assistancesIsLoading,
} = useMutation(
  (values: Values) =>
    serviceApi.assistances(props.service.id, props.service.contractId, values),
  {
    onSuccess: () => {
      if (hasLoyaltyCostAndRedemption.value) {
        updateServiceRedemptionMutate();
      }
      showSuccessPage.value = true;
      formError.value = false;
    },
    onError: () => {
      showSuccessPage.value = false;
      formError.value = true;
    },
  },
);

function translateFormData(values: Values) {
  const translatedValues: Values = {};
  Object.keys(values).forEach((key) => {
    const lookupName = key.split('-')[0];
    const input = props?.service?.inputs?.find((i) =>
      [i.name, i.firstName, i.secondName].includes(lookupName),
    );
    const { apiName, firstName, firstApiName, secondApiName, kind } = input;

    if (apiName) {
      translatedValues[`${apiName}-${kind}`] = values[key];
    } else {
      const apiNameKey = firstName === key ? firstApiName : secondApiName;
      translatedValues[`${apiNameKey}-${kind}`] = values[key];
    }
  });
  translatedValues['comuna_origen-timing'] = values['address-timing']?.locality || '';
  translatedFormValues.value = translatedValues;
}

function addPhonePrefix(values: Values) {
  props.service.inputs?.forEach((input) => {
    if (input.inputType === 'mobile_phone' && 'name' in input) {
      const inputKey = `${input.name}-${input.kind}`;
      values[inputKey] = `${props.phonePrefix}${values[inputKey]}`;
    }
  });
}

function askAssistance(values: Values) {
  addPhonePrefix(values);
  translateFormData(values);
  assistancesMutate(translatedFormValues.value);
}

function validateForm(values: Values) {
  formValues.value = { ...formValues.value, ...values };
  if (isLastStep.value) {
    askAssistance(formValues.value);
  } else {
    nextStep();
  }
}

function toggleRedemptionError() {
  showRedemptionError.value = !showRedemptionError.value;
}

const isLoading = computed(() => (
  assistancesIsLoading.value || updateServiceRedemptionIsLoading.value
));

onMounted(() => {
  const referrerUrl = sessionStorage.getItem('referrerUrl') || props.backUrl;
  const familyIndex = sessionStorage.getItem('selectedFamilyIndex');
  const url = familyIndex ? `${referrerUrl}?family_index=${familyIndex}` : referrerUrl;
  sessionStorage.setItem('backUrl', url);
  sessionStorage.removeItem('referrerUrl');
  sessionStorage.removeItem('selectedFamilyIndex');
});

function getBackUrl(): void {
  const storedUrl = sessionStorage.getItem('backUrl');
  window.location.href = storedUrl || props.backUrl;
}

function closeModal(): void {
  formError.value = false;
}

</script>

<template>
  <loyalty-redemption-error-modal
    :open="showRedemptionError"
    @close="toggleRedemptionError"
  />
  <div
    v-if="!showSuccessPage"
    class="bg-gray-50"
  >
    <div class="flex items-center px-8 sm:mt-4 md:mt-6 md:px-20">
      <base-link
        theme="gray"
        icon-file-name="back"
        icon-position="left"
        icon-size="sm"
        :label="$t('common.back')"
        class="flex-none"
        :data-testid="'back-button'"
        @click.prevent="getBackUrl"
      />
      <service-form-error-modal
        :active="formError"
        @close="closeModal"
      />
    </div>
    <div class="px-6 pt-2 sm:text-center">
      <span class="text-2xl font-medium sm:text-3xl">{{ $t('serviceFormInfo.title.firstPart') }}</span>
      <span class="text-2xl font-medium text-primary sm:text-3xl">
        {{ service.name }}
        <span class="pt-3 text-2xl font-medium text-black sm:block sm:text-lg sm:font-normal sm:text-gray-500">{{ $t('serviceFormInfo.title.secondPart', {steps: steps.length}) }}</span>
      </span>
    </div>
    <div class="flex flex-1 flex-col items-center justify-center px-6 pt-8 sm:pt-14">
      <service-form-card
        :service="service"
      />
      <div
        v-for="(inputGroup, group, index) in groupedInputs"
        :key="group"
        class="w-full sm:max-w-2xl"
      >
        <v-form
          v-if="currentStepIndex === index"
          ref="form"
          :validation-schema="validationSchemas[currentStepIndex]"
          class="flex w-full flex-col items-center "
          :data-testid="'service-form-' + index"
          @submit="validateForm"
        >
          <div class="w-full rounded-2xl pt-8 sm:bg-white sm:p-8 sm:shadow-mok-md">
            <base-mok-stepper
              :steps="steps"
              :current-step="currentStepIndex"
              class="px-2 pb-6 pt-4 sm:px-16"
            />
            <div
              class="sm:my-8 sm:h-px sm:w-full sm:bg-gray-300"
            />
            <h2 class="mb-5 text-black">
              {{ $t(`serviceFormInfo.${group}.title`) }}
            </h2>
            <div class="grid grid-cols-1 gap-8 md:grid-cols-2">
              <template
                v-for="input in inputGroup"
                :key="`service-input-${input.id}`"
              >
                <service-form-nested-input
                  v-if="input.inputType.includes('nested')"
                  :input="input"
                  :translation-path="`serviceFormInfo.${group}.inputs`"
                />
                <service-form-input
                  v-else
                  :input="input"
                  :translation-path="`serviceFormInfo.${group}.inputs`"
                  :country-flag-icon="countryFlagIcon"
                  :phone-prefix="phonePrefix"
                />
              </template>
            </div>
          </div>
          <div class="my-8 flex w-full justify-end gap-2">
            <base-button
              v-if="currentStepIndex > 0"
              type="button"
              theme="primary-button-outline"
              @click="previousStep"
            >
              {{ $t('common.back') }}
            </base-button>
            <base-button
              v-if="!isLastStep"
              type="submit"
              theme="primary-button"
              :data-testid="'continue-button'"
            >
              {{ $t('serviceFormInfo.continue') }}
            </base-button>
            <base-button
              v-else
              type="submit"
              theme="primary-button"
              :loading="isLoading"
              :disabled="isLoading"
            >
              {{ $t('serviceFormInfo.request') }}
            </base-button>
          </div>
        </v-form>
      </div>
    </div>
  </div>
  <service-form-success-page
    v-else
    :service="service"
    :form-values="formValues"
  />
</template>
